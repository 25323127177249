import { Link } from 'gatsby';
import * as React from 'react';

import { Container } from '../containers/container';
import { Color, Logo } from '../elements/logo';
import { Navigation } from './navigation';
import { NavigationBurger } from './navigation-burger';

const Header = (): React.ReactElement => {
    const [mobileMenuIsVisible, toggleMobileMenu] = React.useState(false);

    return (
        <header>
            <Container className={'py-8'}>
                <div className={'flex items-center space-x-8'}>
                    <Link to={'/'} className={'flex-none'} aria-label={'Terug naar homepage'}>
                        <Logo color={Color.Caribbean} />
                    </Link>
                    <div className={'flex-grow'}>
                        <Navigation
                            // callToAction={<Button url={'/'} caption={'Gratis proefles'} text={'Je zit nergens aan vast'} />}
                            mobileMenuIsVisible={mobileMenuIsVisible}
                            toggleMobileMenu={toggleMobileMenu}
                            className={'hidden md:block'}
                        />
                    </div>
                    <NavigationBurger
                        mobileMenuIsVisible={mobileMenuIsVisible}
                        toggleMobileMenu={toggleMobileMenu}
                        className={'flex-none md:hidden z-50'}
                    />
                </div>
            </Container>
        </header>
    );
};

export { Header };
