import PropTypes from 'prop-types';
import * as React from 'react';

import { Container } from './container';

type Properties = {
    content: React.ReactChild | React.ReactChild[];
    sidebar: React.ReactChild | React.ReactChild[];
};

const PageSplit = ({ content, sidebar }: Properties): React.ReactElement => {
    return (
        <Container className={'py-section'}>
            <div className={'grid md:grid-cols-12 gap-12'}>
                <div className={'md:col-span-7 lg:col-span-8'}>{content}</div>
                <div className={'md:col-span-5 lg:col-span-4'}>{sidebar}</div>
            </div>
        </Container>
    );
};

PageSplit.propTypes = {
    content: PropTypes.node.isRequired,
    sidebar: PropTypes.node.isRequired,
};

PageSplit.defaultProps = {};

export { PageSplit };
