import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';

type Properties = {
    mobileMenuIsVisible: boolean;
    toggleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;

    callToAction?: React.ReactElement;
    className?: string;
};

const Navigation = ({ mobileMenuIsVisible, toggleMobileMenu, callToAction, className }: Properties): React.ReactElement => {
    const links = [
        { url: '/', title: 'Home' },
        { url: '/leefstijlcoaching', title: 'Leefstijlcoaching' },
        { url: '/trainingen', title: 'Trainingen' },
        { url: '/powerwalking', title: 'Powerwalking' },
        { url: '/contact', title: 'Contact' },
    ];

    const hideMobileMenu = (): void => {
        toggleMobileMenu(false);
    };

    return (
        <div className={'flex flex-row items-center justify-between'}>
            <nav className={classNames('space-x-6', className)}>
                {links.map((link, index) => {
                    const isCurrent = useLocation().pathname === link.url;

                    return (
                        <Link
                            key={index}
                            to={link.url}
                            className={classNames('pb-2 border-b-8 hover:text-caribbean hover:border-lemon', {
                                'border-transparent': !isCurrent,
                                'border-lemon': isCurrent,
                            })}>
                            {link.title}
                        </Link>
                    );
                })}
            </nav>
            <div
                className={classNames(
                    'flex fixed flex-col items-center justify-between top-0 left-0 w-screen z-40 overflow-x-scroll pt-32 px-12 pb-8 bg-caribbean menu md:hidden',
                    {
                        'menu--active': mobileMenuIsVisible,
                    }
                )}>
                <Helmet>
                    <body className={classNames({ 'overflow-hidden': mobileMenuIsVisible })} />
                </Helmet>
                <div className={'flex flex-col items-center mt-5'}>
                    {links.map((link, index) => {
                        const isCurrent = useLocation().pathname === link.url;

                        return (
                            <Link
                                key={index}
                                to={link.url}
                                onClick={isCurrent ? hideMobileMenu : undefined}
                                className={classNames('mb-4 pb-1 border-b-8 border-transparent text-2xl text-white focus:border-lemon', {
                                    'border-lemon': isCurrent,
                                })}>
                                {link.title}
                            </Link>
                        );
                    })}
                </div>
                {callToAction && <div className={'my-4'}>{callToAction}</div>}
                <div className={'space-x-2'}>
                    <a
                        href={'https://facebook.com/fitfoodforty'}
                        className={'text-white hover:text-lemon'}
                        target={'_blank'}
                        rel={'noopener'}
                        title={'Volg Fitfoodforty op Facebook'}>
                        <FontAwesomeIcon icon={['fab', 'facebook-square']} size={'2x'} />
                    </a>{' '}
                    <a
                        href={'https://instagram.com/fitfoodforty'}
                        className={'text-white hover:text-lemon'}
                        target={'_blank'}
                        rel={'noopener'}
                        title={'Volg Fitfoodforty op Instagram'}>
                        <FontAwesomeIcon icon={['fab', 'instagram']} size={'2x'} />
                    </a>
                </div>
            </div>
            {callToAction && <div className={'hidden md:block'}>{callToAction}</div>}
        </div>
    );
};

Navigation.propTypes = {
    mobileMenuIsVisible: PropTypes.bool,
    toggleMobileMenu: PropTypes.func.isRequired,

    callToAction: PropTypes.object,
    className: PropTypes.string,
};

Navigation.defaultProps = {
    className: '',
};

export { Navigation };
