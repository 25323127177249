import classNames from 'classnames';
import { Link as PageLink } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

enum Style {
    Primary = 'primary',
    Secondary = 'secondary',
}

enum Type {
    Button = 'button',
    Link = 'link',
    Submit = 'submit',
}

type Properties = {
    style?: Style;
    type?: Type;
    url?: string;

    forceBlank?: boolean;

    children: React.ReactChild | React.ReactChild[];
    className?: string;
};

const buildButtonClassNames = (style: Style, className?: string): string => {
    return classNames(
        'inline-block py-4 px-6 transform transition hover:-translate-y-0.5 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-opacity-50 text-center leading-none rounded-lg shadow-lg cursor-pointer',
        {
            'bg-lemon text-black hover:bg-lemon-400 focus:ring-lemon active:bg-lemon-550': Style.Primary === style,
            'bg-caribbean text-white hover:bg-caribbean-400 focus:ring-caribbean active:bg-caribbean-550': Style.Secondary === style,
        },
        className
    );
};

const renderButton = (
    style: Style,
    type: Type,
    children: React.ReactChild | React.ReactChild[],
    className: string | undefined
): React.ReactElement => {
    return (
        <button type={Type.Submit === type ? 'submit' : 'button'} className={buildButtonClassNames(style, className)}>
            {children}
        </button>
    );
};

const renderLink = (
    style: Style,
    url: string,
    forceBlank: boolean,
    children: React.ReactChild | React.ReactChild[],
    className: string | undefined
): React.ReactElement => {
    if (!url.startsWith('/')) {
        return (
            <a href={url} rel={'noopener'} target={forceBlank ? '_blank' : '_self'} className={buildButtonClassNames(style, className)}>
                {children}
            </a>
        );
    }

    return (
        <PageLink to={url} className={buildButtonClassNames(style, className)}>
            {children}
        </PageLink>
    );
};

const Button = ({ style, type, url, forceBlank, children, className }: Properties): React.ReactElement => {
    const renderStyle = style || Button.defaultProps.style;
    const renderType = type || Button.defaultProps.type;

    const renderForceBlank = undefined === forceBlank ? Button.defaultProps.forceBlank : forceBlank;

    if (Type.Link === renderType) {
        if (undefined === url) {
            throw new Error('Cannot render link button without a URL.');
        }

        return renderLink(renderStyle, url, renderForceBlank, children, className);
    }

    if (Type.Button === renderType || Type.Submit === renderType) {
        if (undefined !== forceBlank) {
            throw new Error('Cannot force blank window on buttons');
        }

        return renderButton(renderStyle, renderType, children, className);
    }

    throw new Error(`Unsupported type setting: "${renderType}"`);
};

Button.propTypes = {
    style: PropTypes.oneOf([Style.Primary, Style.Secondary]),
    type: PropTypes.oneOf([Type.Button, Type.Link, Type.Submit]),
    url: PropTypes.string,
    forceBlank: PropTypes.bool,

    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Button.defaultProps = {
    style: Style.Primary,
    type: Type.Button,
    forceBlank: false,

    className: '',
};

export { Button, Style, Type };
export { buildButtonClassNames };
