import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { PageSplit } from '../components/containers/page-split';
import { Panel } from '../components/containers/panel';
import { Button, Type as ButtonType } from '../components/elements/button';
import { Header } from '../components/layout/header';
import { Site } from '../components/shells/site';
import { PageTitle } from '../components/typography/page-title';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo title="Contact" />

            <Header />

            <PageSplit
                content={
                    <>
                        <PageTitle>Contact</PageTitle>
                        <div className={'prose'}>
                            <p>
                                Heb je interesse in wat ik voor jou als{' '}
                                <Link to={'/leefstijlcoaching'} className={'underline'}>
                                    leefstijlcoach
                                </Link>{' '}
                                kan betekenen, over{' '}
                                <Link to={'/powerwalking'} className={'underline'}>
                                    powerwalking
                                </Link>{' '}
                                of iets anders? Neem dan contact met mij op!
                            </p>
                            <p>
                                Wil je op de hoogte blijven? Volg mij ook op{' '}
                                <a href={'https://instagram.com/fitfoodforty'} target={'_blank'} rel={'noopener'} className={'underline'}>
                                    Instagram
                                </a>{' '}
                                of{' '}
                                <a href={'https://facebook.com/fitfoodforty'} target={'_blank'} rel={'noopener'} className={'underline'}>
                                    Facebook
                                </a>
                                .
                            </p>
                        </div>
                        <div className={'flex flex-col sm:flex-row justify-start mt-6 gap-6'}>
                            <Button type={ButtonType.Link} url={'mailto:vicky@fitfoodforty.nl'}>
                                <FontAwesomeIcon icon={['far', 'envelope-open']} size={'1x'} className={'mr-2'} />
                                <span className={'font-bold'}>Stuur een e-mail</span>
                            </Button>

                            <Button type={ButtonType.Link} url={'https://wa.me/31641777848'}>
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'mr-2'} />
                                <span className={'font-bold'}>WhatsApp</span>
                            </Button>
                        </div>
                    </>
                }
                sidebar={
                    <>
                        <div className={'w-full max-w-sm mx-auto'}>
                            <StaticImage src={'../../assets/images/section-split-vicky-2.jpg'} alt={''} className={'rounded-full'} />
                        </div>
                        <Panel className={'mt-12'}>
                            <strong>Fitfoodforty</strong>
                            <p>
                                Kerkstraat 106
                                <br />
                                6267 EG Cadier en Keer
                            </p>
                            <p className={'mt-2'}>
                                KvK 73882488
                                <br />
                                BTW-ID NL001815704B36
                            </p>
                            <p className={'mt-2'}>NL39 KNAB 0258 4715 14</p>
                        </Panel>
                        <div className={'mt-10 flex justify-center'}>
                            <a href={'https://blcn.nl/leefstijlcoach/fitfoodforty'} target={'_blank'}>
                                <StaticImage src={'../../assets/images/blcn-logo.png'} alt={''} className={'w-40'} />
                            </a>
                        </div>
                    </>
                }
            />
        </Site>
    );
};
