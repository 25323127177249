import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';

type Properties = {
    mobileMenuIsVisible: boolean;
    toggleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;

    className?: string;
};

const NavigationBurger = ({ mobileMenuIsVisible, toggleMobileMenu, className }: Properties): React.ReactElement => {
    return (
        <button
            type={'button'}
            onClick={(): void => toggleMobileMenu(!mobileMenuIsVisible)}
            aria-label={mobileMenuIsVisible ? 'Navigatie verbergen' : 'Navigatie weergeven'}
            className={classNames('hamburger hamburger--arrowturn', { 'is-active': mobileMenuIsVisible }, className)}>
            <span className={'hamburger-box'}>
                <span className={'hamburger-inner'} />
            </span>
        </button>
    );
};

NavigationBurger.propTypes = {
    mobileMenuIsVisible: PropTypes.bool,
    toggleMobileMenu: PropTypes.func.isRequired,

    className: PropTypes.string,
};

NavigationBurger.defaultProps = {
    className: '',
};

export { NavigationBurger };
